import React from "react"
import Layout from "../components/layout"
import DancerList from "../components/lists/DancerList"
import SEO from "../components/seo"
import { Container, MainHeading } from "../components/styled/Styled"

const DancerPage = () => {
  return (
    <Layout>
      <SEO title="Dancers" />
      <Container>
        <MainHeading style={{ textAlign: "center" }}>DANCERS</MainHeading>
        <DancerList />
      </Container>
    </Layout>
  )
}

export default DancerPage
