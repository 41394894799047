import React from "react"
import PropTypes from "prop-types"
import { motion } from "framer-motion"
import { graphql, useStaticQuery, Link } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import { MEDIA_QUERIES, COLORS, FONT_FAMILIES } from "../../constants"

const Items = styled(motion.div)`
  display: flex;
  margin: auto;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: start;
`

const Item = styled(Link)`
  position: relative;
  display: flex;
  width: 50%;
  background-color: ${COLORS.GREY};
  cursor: pointer;
  height: 500px;

  &:nth-child(3) {
    flex-direction: row-reverse;
  }

  &:nth-child(4) {
    flex-direction: row-reverse;
  }

  &:nth-child(7) {
    flex-direction: row-reverse;
  }

  &:nth-child(8) {
    flex-direction: row-reverse;
  }

  &:nth-child(11) {
    flex-direction: row-reverse;
  }

  &:nth-child(12) {
    flex-direction: row-reverse;
  }

  @media (max-width: ${MEDIA_QUERIES.LARGE}) {
    width: 100%;
  }

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    flex-direction: column;
    &:nth-child(even) {
      flex-direction: row;
    }
    &:nth-child(odd) {
      flex-direction: row-reverse;
    }
    height: 300px;
  }
`

const ImageContainer = styled.div`
  width: 50%;
  height: 100%;
  overflow: hidden;
`

const DancerImage = styled(Image)`
  transition: transform 0.65s ease-in-out;
  height: 100%;
  width: 100%;
  &:hover {
    transform: scale(1.1);
  }
`

const Article = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  color: ${COLORS.SECONDARY};
  &:hover {
    transform: scale(1.1);
  }

  transition: transform 0.65s ease-in-out;
`

const Role = styled.p`
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  margin-top: 1rem;
  line-height: 1.5rem;
  color: ${COLORS.SECONDARY};
  font-family: ${FONT_FAMILIES.TEXT};
  font-size: 1.2rem;
  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    font-size: 0.9rem;
    font-weight: 600;
  }
`

const Paragraph = styled.p`
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  line-height: 1.5rem;
  color: ${COLORS.SECONDARY};
  font-family: ${FONT_FAMILIES.BUTTON};
  font-size: 1.6rem;

  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
    font-size: 1.2rem;
  }
`

const DancerList = () => {
  const {
    allMarkdownRemark: { nodes: dancers },
  } = useStaticQuery(graphql`
    query EventQuery {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "//dancer//" } }) {
        nodes {
          fields {
            slug
          }
          frontmatter {
            firstname
            lastname
            role
            featuredimage {
              childImageSharp {
                fluid(quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Items
      initial={{ opacity: 0, x: 400 }}
      animate={{
        opacity: 1,
        x: 0,
        transition: {
          delay: 0.5,
          duration: 0.75,
          ease: "easeOut",
          type: "spring",
          damping: 50,
        },
      }}
    >
      {dancers &&
        dancers.map(({ fields: { slug }, frontmatter: dancer }, i) => (
          <Item to={slug} key={i}>
            <ImageContainer>
              <DancerImage fluid={dancer.featuredimage.childImageSharp.fluid} />
            </ImageContainer>
            <Article>
              <Paragraph>{dancer.firstname}</Paragraph>
              <Paragraph>{dancer.lastname}</Paragraph>
              <Role>{dancer.role}</Role>
            </Article>
          </Item>
        ))}
    </Items>
  )
}

DancerList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default DancerList
